import cx from "classnames"
import React, { useEffect, useState } from "react"
import useTranslation from "src/hooks/useTranslation"
import { renderIcon } from "../Icon"
import ButtonToggle from "./ButtonToggle"
import styles from "./style.module.scss"

export const ButtonToggleGroupContext = React.createContext(null)

export default function ButtonToggleGroup({
  children,
  onButtonToggleChange,
  initialValue,
  buttons = null,
  className = null
}) {
  const [buttonToggleSelected, setButtonToggleSelected] = useState(initialValue)

  const { t } = useTranslation()

  useEffect(() => {
    if (!initialValue) {
      return
    }
    setButtonToggleSelected(initialValue)
  }, [initialValue])

  const renderChildren = () => {
    if (!buttons) {
      return children
    }

    return Object.entries(buttons).map(([k, v]) => {
      return (
        <ButtonToggle
          key={k}
          value={k}
          tooltip={t(v.tooltip)}
          className={cx("ui button icon compact basic", v.className)}
        >
          {renderIcon(v.icon)}
        </ButtonToggle>
      )
    })
  }

  return (
    <ButtonToggleGroupContext.Provider
      value={{
        buttonToggleSelected,
        setButtonToggleSelected,
        initialValue,
        onButtonToggleChange
      }}
    >
      <div className={cx(styles.wrapper, className, buttons && "ui buttons")}>{renderChildren()}</div>
    </ButtonToggleGroupContext.Provider>
  )
}
