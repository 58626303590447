import React, { useReducer, useState } from "react"
import Button from "../../components/Button"
import Dialog from "../../components/Dialog"
import DialogBody from "../../components/Dialog/DialogBody"
import DialogFooter from "../../components/Dialog/DialogFooter"
import DialogHeader from "../../components/Dialog/DialogHeader"
import FormField from "../../components/FormField"
import Select from "../../components/FormField/Select"
import { updateAudit } from "../../services/audit.service"
import useDispatch from "../../store"
import { deleteAudit } from "../../store/audits/actions"

const SET_VALUE = "SET_VALUE"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_VALUE:
      const updatedValues = {
        ...state.inputsValue,
        [action.key]: action.value
      }

      const updatedValidity = {
        ...state.inputsValidity,
        [action.key]: action.value ? true : false
      }

      return {
        ...state,
        inputsValue: updatedValues,
        inputsValidity: updatedValidity
      }

    default:
      return state
  }
}

export default function AuditPostpone({ isOpen = false, onClose = () => {}, onAccept = () => {}, auditData }) {
  const dispatch = useDispatch()

  const [yearOptions, setYearOptions] = useState(() => {
    const years = []
    const auditYear = auditData.ano_auditoria
    const maxPostpone = auditYear + 5

    for (let i = maxPostpone; i > auditYear; i--) {
      years.push({
        id: i,
        label: i,
        selected: false
      })
    }

    const found = years.find((el) => el.id === auditData.ano_auditoria)

    if (found) {
      found.selected = true
    }

    return years
  })

  const [formState, dispatchForm] = useReducer(formReducer, {
    inputsValue: {
      year: null,
      status: "APLAZADO"
    },
    inputsValidity: {
      year: false
    }
  })
  if (!isOpen) return null

  return (
    <Dialog isOpen={isOpen}>
      <DialogHeader background='#0a858d' color='#e6e6e6' align='center' buttonClose={true} handleClose={onClose}>
        <h3>Elige el año</h3>
      </DialogHeader>
      <DialogBody background='#fff'>
        <FormField>
          <Select
            selectionChange={({ id }) => {
              dispatchForm({
                type: SET_VALUE,
                value: id,
                key: "year"
              })
            }}
            initialOptions={yearOptions}
          />
        </FormField>
      </DialogBody>
      <DialogFooter background='#fff' align='right'>
        <Button text='Cancelar' name='warning' type='big' handleClick={onClose} />
        <Button
          text='Aceptar'
          name='normal'
          type='big'
          handleClick={async () => {
            const thereIsInvalidInput = Object.entries(formState.inputsValidity).some(([key, value]) => {
              return !value
            })

            if (thereIsInvalidInput) return
            try {
              await updateAudit({
                auditId: auditData.id,
                auditBody: {
                  ano_auditoria: formState.inputsValue.year,
                  status: formState.inputsValue.status
                }
              }).then((_) => {
                dispatch(deleteAudit(auditData.id))
                onAccept()
              })
            } catch (error) {
              console.log(error, "error")
            } finally {
            }
          }}
        />
      </DialogFooter>
    </Dialog>
  )
}
