import cx from "classnames"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Icon as IconSemantic } from "semantic-ui-react"
import Dialog from "src/components/Dialog"
import Loading from "src/components/Loading"
import { auditStatus } from "src/constants/audit"
import { buildAuditPermissionValue } from "src/helpers/audit"
import formatMessage from "src/helpers/formatMessageDisabled"
import useAccessControl from "src/hooks/useAccessControl"
import { useAuditAccessControl } from "src/hooks/useAuditAccessControl"
import useTranslation from "src/hooks/useTranslation"
import { getAuditMyPermissions } from "src/services/audit.service"
import Icon from "../../../components/Icon"
import Tooltip from "../../../components/Tooltip"
import styles from "./style.module.scss"

export const rowActions = Object.freeze({
  ENTER: "ENTER",
  EDIT: "EDIT",
  CLOSE: "CLOSE",
  CANCEL: "CANCEL",
  DELETE: "DELETE",
  POSTPONE: "POSTPONE",
  POSTPONE_REVERT: "POSTPONE_REVERT",
  CHANGE_FREQ: "CHANGE_FREQ"
})

const AUDITORIA_ACTIONS = [
  { action: "EDIT", icon: "pencil", label: "frontend.auditoria.btn_edit", permission: "audit.edit" },
  {
    action: "CHANGE_FREQ",
    icon: "exchange",
    label: (audit) => [
      "frontend.auditoria.btn_changefreq",
      { freq: `$t(frontend.auditoria.fields.frequency__choices.${audit.tipo_frecuencia == "PRG" ? "ESP" : "PRG"})` }
    ],
    permission: "audit.edit"
  },
  {
    action: "BECOME_TEMPLATE",
    icon: "star",
    label: "frontend.auditoria.template.btn_become_template",
    permission: "audit.become_template"
  },
  {
    action: "CLOSE",
    icon: "flag checkered",
    label: "frontend.auditoria.btn_close",
    permission: "audit.close",
    creator: false
  },
  {
    action: "CANCEL",
    icon: "undo",
    label: "frontend.auditoria.btn_reopen",
    permission: "audit.cancel",
    visibilityCallback(auditoria) {
      return auditoria.status == "ANULADO"
    }
  },
  {
    action: "CANCEL",
    icon: "times circle outline",
    label: "frontend.auditoria.btn_cancel",
    permission: "audit.reopen",
    visibilityCallback(auditoria) {
      return auditoria.status !== "ANULADO"
    }
  },
  {
    action: "POSTPONE",
    icon: "calendar check outline",
    label: "frontend.auditoria.btn_postpone",
    permission: "audit.postpone"
  }
]

function ActionsModal({ auditoria, onClickHandler }) {
  const [open, setOpen] = useState(false)
  const [permissions, setPermissions] = useState(null)

  const { t } = useTranslation()

  const { getCurrentUser, isUserAdmin } = useAccessControl()
  const { checkUserHasAuditPermissionValue } = useAuditAccessControl()

  const user = getCurrentUser()

  useEffect(() => {
    setPermissions(null)
    if (!open) {
      return
    }

    getAuditMyPermissions(auditoria.id).then(({ data }) => {
      setPermissions(data.permissions)
    })
  }, [auditoria.id, open])

  const handleClick = (action) => {
    return (ev) => {
      onClickHandler(action, ev)
      setOpen(false)
    }
  }

  const renderLabel = (label) => {
    if (typeof label === "function") {
      const [l, p] = label(auditoria)
      return t(l, p)
    }
    if (typeof label === "string") {
      return t(label)
    }
  }

  const renderContent = () => {
    if (permissions === null) {
      return <Loading type={"PulseLoader"} text='main_ui.general.lb_loading' />
    }

    return (
      <div>
        <p style={{ marginBottom: 20 }}>{t("main_ui.general.lb_click_on_action")}</p>
        <div className='ui cards'>
          {AUDITORIA_ACTIONS.map((action) => {
            if (typeof action.visibilityCallback !== "undefined" && !action.visibilityCallback(auditoria)) {
              return null
            }

            const disabled =
              (action.creator ?? true) && auditoria.created_by?.id == user.id
                ? false
                : !isUserAdmin() && !checkUserHasAuditPermissionValue(`${permissions[action.permission]}`)

            return (
              <div
                key={action.action}
                className={cx("ui centered raised card", styles.actionCard, disabled && ["disabled", styles.disabled])}
              >
                <div className='content'>
                  <div className='center aligned header'>
                    <i className={cx(action.icon, "icon")} />
                    {renderLabel(action.label)}
                  </div>
                </div>
                <div
                  className={cx("ui bottom attached button", !disabled && "primary")}
                  onClick={disabled ? null : handleClick(action.action)}
                >
                  {disabled ? (
                    <Tooltip content={t("main_ui.general.error_unauthorized_action")} title={t(action.label)}>
                      <i className='ui icon times circle outline' />
                      {t("main_ui.general.lb_disabled_option_title")}
                    </Tooltip>
                  ) : (
                    <>
                      <i className='ui icon check' />
                      {t("main_ui.general.btn_select")}
                    </>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.button} onClick={() => setOpen(true)}>
        <Tooltip content={t("frontend.auditoria.modal_actions___tooltip")}>
          <i className='icons large'>
            <i className='icon folder open' />
            <i className='corner icon cog' />
          </i>
        </Tooltip>
      </div>

      {open && (
        <Dialog isOpen>
          <Dialog.Header theme='light' handleClose={() => setOpen(false)} buttonClose>
            {t("frontend.auditoria.modal_actions___title", {
              auditoria: `${auditoria.codigo}-${auditoria.nombre} (${auditoria.ano_auditoria})`
            })}
          </Dialog.Header>
          <Dialog.Body theme='light'>{renderContent()}</Dialog.Body>
        </Dialog>
      )}
    </>
  )
}

export default function Actions({ row, onActionClick }) {
  const { status } = row
  const culmined = row.status == "CULMINADO" && row?.fecha_cierre_real !== null

  const { user } = useSelector((state) => state)

  const { t } = useTranslation()
  const { hasPermission, hasNonReadonlyPermission, isUserAdmin } = useAccessControl()

  const onClickHandler = (action, e) => {
    e?.stopPropagation()
    onActionClick(action)
  }

  const viewReOpenButton = culmined && (isUserAdmin() || user?.email === row?.created_by?.email || user?.role === "PM")

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
      {hasPermission("pyr_auditoria.work", buildAuditPermissionValue(row)) && (
        <div className={styles.button} onClick={onClickHandler.bind(this, rowActions.ENTER)}>
          <Tooltip
            content={
              <div>
                {auditStatus.DISCONTINUED.value === status ? (
                  formatMessage({
                    type: "disabled",
                    button: t("frontend.auditoria.btn_enter"),
                    motive: t("frontend.auditoria.error_nulled_state")
                  })
                ) : (
                  <h3>{t("frontend.auditoria.btn_enter")}</h3>
                )}
              </div>
            }
            style={{
              maxWidth: 400
            }}
          >
            <div
              style={{
                opacity: auditStatus.DISCONTINUED.value === status ? 0.5 : 1
              }}
            >
              <Icon name='eye' />
            </div>
          </Tooltip>
        </div>
      )}

      {viewReOpenButton && (
        <div onClick={onClickHandler.bind(this, rowActions.CANCEL)}>
          <Tooltip
            content={<div>Re-abrir auditoría</div>}
            style={{
              maxWidth: 400
            }}
          >
            <IconSemantic name='redo' />
          </Tooltip>
        </div>
      )}

      {!culmined && (
        <>
          <ActionsModal auditoria={row} onClickHandler={onClickHandler} />

          {hasNonReadonlyPermission("pyr_auditoria.delete", buildAuditPermissionValue(row)) && (
            <div className={styles.button} onClick={onClickHandler.bind(this, rowActions.DELETE)}>
              <Tooltip content={t("main_ui.general.btn_delete")}>
                <Icon name='trash' />
              </Tooltip>
            </div>
          )}
        </>
      )}
    </div>
  )
}
