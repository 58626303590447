import React from 'react'
import styles from './style.module.scss'

export default function KanbanHeader({
  isLast = false,
  children,
  color = 'green'
}) {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <div
        className={`${styles.kanbanHeader} ${
          isLast ? styles.kanbanHeaderLast : ''
        }`}
      >
        {children}

        <div className={styles.headerCurve} />
      </div>
      <div style={{ backgroundColor: color, height: 3 }} />
    </div>
  )
}
