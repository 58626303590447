import cx from "classnames"
import { get as getvalue } from "get-wild"
import ErrorBoundary from "src/errors/ErrorBoundary"
import KanbanHeader from "./KanbanHeader"
import styles from "./style.module.scss"

export default function Kanban({
  data = [],
  displayedColumns = [],
  config: { columns, filterBy } = {},
  emptyMessage = null
}) {
  const itemsAmount = (key) => data?.filter((el) => calcFilterBy(el) === key).length

  const calcFilterBy = (el) => {
    if (typeof filterBy === "string") {
      return getvalue(el, filterBy)
    }

    if (typeof filterBy === "function") {
      return filterBy(el)
    }

    return null
  }

  return (
    <ErrorBoundary>
      <div className={styles.kanbanWrapper}>
        <div className={styles.kanban}>
          {displayedColumns.map((key, index) => {
            return (
              <div className={styles.kanbanColumn} style={{ width: `${100 / displayedColumns.length}%` }} key={key}>
                <KanbanHeader isLast={displayedColumns.length === index + 1} color={columns[key].color}>
                  {columns[key].headerCell(itemsAmount(key))}
                </KanbanHeader>
                <div style={{ padding: 10, display: "flex", flexDirection: "column", gap: 10 }}>
                  {columns[key].beforeCell?.call(columns[key])}
                  {data?.map((el) => {
                    if (calcFilterBy(el) === key) {
                      return columns[key].cell(el)
                    }
                    return null
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {(!data || data.length === 0) && emptyMessage && (
        <div className={cx("ui segment basic warning", styles.emptyMessage)}>{emptyMessage}</div>
      )}
    </ErrorBoundary>
  )
}
