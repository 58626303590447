import { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { getExpirationData } from "src/services/expiration.service"

export default function ExpirationAlert() {
  const [data, setData] = useState(null)

  useEffect(() => {
    getExpirationData().then(({ data }) => {
      setData(data)
    })
  }, [])

  if (data === null || data?.count === null || data?.count == 0) {
    return null
  }

  const list = data?.expiration_dates?.[0] || {}

  return (
    <div className='ui message orange' style={{ cursor: "pointer" }}>
      <Trans count={data?.count}>
        <>
          Tienes
          <div className='ui label circular red' style={{ margin: "0 5px 0 5px" }}>
            {{ count: data?.count }}
          </div>
          {data?.count === 1 ? "usuario próximo a vencer" : "usuarios próximos a vencer"}:{" "}
          {data?.count === 1 && <strong>{Object.keys(list)[0]}</strong>}
          {data?.count > 1 && (
            <ul style={{ marginTop: "1rem" }}>
              {Object.keys(list).map((item, index) => {
                return (
                  <li key={index}>
                    <strong>{String(list[item])}</strong> {list[item] <= 1 ? "usuario vence el" : "usuarios vencen el"}{" "}
                    <strong>{item}.</strong>
                  </li>
                )
              })}
            </ul>
          )}
        </>
      </Trans>
    </div>
  )
}
