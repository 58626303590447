import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import Link from "next/link"
import { isValidElement, useState } from "react"
import Icon from "src/components/Icon"
import Tooltip from "src/components/Tooltip"
import useAccessControl from "src/hooks/useAccessControl"
import useTranslation from "src/hooks/useTranslation"
import styles from "./style.module.scss"

export default function SidenavOption({
  icon = null,
  children = null,
  onClick = null,
  tooltip = "",
  badge = false,
  href = null,
  disabled = false,
  permission = null,
  componentModal = null,
  ...attrs
}) {
  const [openModal, setOpenModal] = useState(false)
  const { hasPermission } = useAccessControl()
  const { i18n } = useTranslation()

  if (onClick === null && componentModal != null && !disabled) {
    onClick = () => {
      setOpenModal(true)
    }
  }

  const renderBadge = () => {
    if (badge === false) {
      return null
    }
    if (badge === null) {
      badge = <FontAwesomeIcon icon={faSyncAlt} size='1x' spin />
    }

    return (
      <div className={styles.badge}>
        <div className={styles.badgeInner}>{badge}</div>
      </div>
    )
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const renderModal = () => {
    if (disabled || componentModal == null) {
      return null
    }

    const Component = componentModal["component"]
    const componentProps = componentModal["props"] ?? {}

    return <Component open={openModal} onClose={onCloseModal} {...componentProps} />
  }

  if (icon && typeof icon == "string" && !icon.startsWith("ui icon")) {
    icon = { name: icon, color: null }
  }

  const renderOption = () => (
    <div className={cx(styles.sidenavOption, disabled && styles.disabled)} onClick={disabled ? null : onClick}>
      {renderBadge()}
      {icon &&
        (isValidElement(icon) ? (
          icon
        ) : typeof icon === "string" ? (
          <i className={icon} />
        ) : isValidElement(icon) ? (
          icon
        ) : (
          <Icon name={icon.name} color={icon?.color} />
        ))}
      {children}
    </div>
  )

  if (permission && !hasPermission(permission)) {
    return null
  }

  return (
    <>
      <Tooltip content={i18n.t(["main_ui.sidenav." + tooltip, tooltip], tooltip)} position='left'>
        {!disabled && href ? (
          <Link href={href}>
            <a target={attrs.target ?? "_blank"}>{renderOption()}</a>
          </Link>
        ) : (
          renderOption()
        )}
      </Tooltip>
      {renderModal()}
    </>
  )
}
