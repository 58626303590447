import cx from "classnames"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Icon from "src/components/Icon"
import Loading from "src/components/Loading"
import Tooltip from "src/components/Tooltip"
import { dateFormatter } from "src/helpers/dateFormatter"
import useAccessControl from "src/hooks/useAccessControl"
import useTranslation from "src/hooks/useTranslation"
import AdvancedTable from "../../components/AdvancedTable"
import Pill from "../../components/Pill"
import { auditStatus } from "../../constants/audit"
import { buildAuditPermissionValue, mapAuditStatus } from "../../helpers/audit"
import { SprintSimpleTableList } from "../AuditSprint/Admin"
import Actions from "./Actions"
import styles from "./style.module.scss"

const EvaluationCell = ({ auditoriaData }) => {
  if (!auditoriaData.evaluaciones) {
    return null
  }

  const evaluaciones = auditoriaData.evaluaciones.map((evaluacion) => {
    return {
      ...evaluacion,
      subdivisiones: auditoriaData.evaluaciones_subdivision
        ? auditoriaData.evaluaciones_subdivision.filter((subd) => subd.evaluacion_id == evaluacion.id)
        : []
    }
  })

  return (
    <div className={cx("ui list", styles.EvaluationCell)}>
      {evaluaciones.map((evaluacion) => {
        return (
          <div key={evaluacion.id} className='item'>
            <div className='header'>
              {evaluacion.nombre} ({evaluacion.ano_evaluacion})
            </div>
            {evaluacion.subdivisiones.length > 0 && (
              <div className={cx("ui list", styles.subdivision)}>
                {evaluacion.subdivisiones.map((subdivision) => {
                  return (
                    <div key={subdivision.id} className='item'>
                      {subdivision.nombre}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default function AuditListView({
  initData,
  paginatorConfig = {
    currentPage: 1,
    totalPages: 500,
    columnSort: "fecha_inicio",
    onNext: () => {},
    onBack: () => {},
    onChangeSort: (v) => {}
  },
  onAction = () => {}
}) {
  const { hasPermission } = useAccessControl()
  const router = useRouter()

  const [data, setData] = useState(initData)

  const { t } = useTranslation()

  useEffect(() => {
    setData(initData)
  }, [initData])

  const calculateProgressColor = (progress) => {
    if (progress < 60) {
      return "var(--accent-red)"
    } else if (progress < 90) {
      return "var(--accent-orange)"
    } else {
      return "var(--accent-green)"
    }
  }

  if (!data) {
    return <Loading text='main_ui.general.lb_loading' curtain={false} />
  }

  return (
    <AdvancedTable
      paginatorConfig={{ ...paginatorConfig }}
      data={data}
      className={styles.table}
      containerStyles={{ display: "flex", flexDirection: "column", flex: 1 }}
      displayedColumns={[
        "id",
        "auditName",
        "auditType",
        "processType",
        "typeAudit",
        "dateRange",
        "budgetedHours",
        "progress",
        "businessUnit",
        "evaluation",
        "leader",
        "status",
        "actions"
      ]}
      sortable
      allowSubrows
      config={{
        rowClassName: (row) => {
          return cx(
            // "status-" + _.camelCase(row.status),
            !hasPermission("pyr_auditoria.work", buildAuditPermissionValue(row)) && "restricted-access"
          )
        },
        rows: {
          id: {
            headerCell: <div>{t("frontend.auditoria.fields.code")}</div>,
            cell: (row) => {
              return (
                <div
                  style={{ cursor: "pointer", wordBreak: "break-word" }}
                  onClick={() => {
                    if (auditStatus.DISCONTINUED.value === row.status)
                      return toast.error(t("frontend.auditoria.error_nulled_state"))

                    router.push(row.id ? `/auditorias/?auditoria-id=${row.id}` : `/`)
                  }}
                >
                  {row.codigo}
                </div>
              )
            },
            sortable: "codigo"
          },
          auditName: {
            headerCell: <div>{t("frontend.auditoria.fields.name")}</div>,
            cell: (row) => {
              return !hasPermission("pyr_auditoria.work", buildAuditPermissionValue(row)) ? (
                row.nombre
              ) : (
                <div>
                  {auditStatus.DISCONTINUED.value === row.status ? (
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        return toast.error(t("frontend.auditoria.error_nulled_state"))
                      }}
                    >
                      {row.nombre}
                    </a>
                  ) : (
                    <a style={{ cursor: "pointer" }} href={row.id ? `/auditorias/?auditoria-id=${row.id}` : `/`}>
                      {row.nombre}
                    </a>
                  )}
                </div>
              )
            },
            sortable: "nombre"
          },
          auditType: {
            headerCell: <div></div>,
            width: 40,
            cell: (row) => {
              return (
                <div
                  title={t(`frontend.auditoria.fields.frequency__choices.${row.tipo_frecuencia}`, row.tipo_frecuencia)}
                >
                  {t(`frontend.auditoria.fields.frequency__choices.${row.tipo_frecuencia}`, row.tipo_frecuencia).charAt(
                    0
                  )}
                </div>
              )
            }
          },
          processType: {
            headerCell: <div>{t("frontend.auditoria.fields.proceso_tipo")}</div>,
            cell: (row) => {
              return <div>{row.proceso_tipo?.nombre}</div>
            }
          },
          typeAudit: {
            headerCell: <div>{t("frontend.auditoria.fields.auditoria_tipo")}</div>,
            cell: (row) => {
              return <div>{row.auditoria_tipo?.nombre}</div>
            }
          },
          dateRange: {
            headerCell: <div>{t("frontend.auditoria.fields.date_range")}</div>,
            cell: (row) => {
              return <div>{`${dateFormatter(row.fecha_inicio) || ""} - ${dateFormatter(row.fecha_fin) || ""}`}</div>
            },
            sortable: "fecha_inicio"
          },
          budgetedHours: {
            headerCell: <div>{t("frontend.auditoria.fields.estimated_hours")}</div>,
            width: 90,
            cell: (row) => {
              return <div>{row.horas_presupuestadas}</div>
            }
          },
          progress: {
            headerCell: <div>{t("frontend.auditoria.fields.progress")}</div>,
            cell: (row) => {
              return (
                <div>
                  <div style={{ textAlign: "center" }}>{row.progreso}%</div>
                  <div className={styles.progressBar}>
                    <div
                      style={{
                        width: parseInt(row.progreso) + "%",
                        backgroundColor: calculateProgressColor(row.progreso)
                      }}
                    ></div>
                  </div>
                </div>
              )
            },
            sortable: "progreso"
          },
          businessUnit: {
            headerCell: <div>{t("frontend.organizacion.unidad_negocio")}</div>,
            cell: (row) => {
              return <div>{`${row.empresa?.nombre} ${String.fromCharCode(187)} ${row.unidad_negocio?.nombre}`}</div>
            }
          },

          leader: {
            headerCell: <div>{t("frontend.auditoria.fields.leader")}</div>,
            cell: (row) => {
              if (row.status == "ANULADO") {
                return "-"
              }

              return row.lider ? (
                <Tooltip title={t("frontend.auditoria.fields.leader")} content={row.lider?.name}>
                  <Icon name='font-awesome/user_circle' size={15} color={"#424242"} />{" "}
                  <span style={{ display: "inline-block", verticalAlign: "top" }}>{row.lider?.sigla}</span>
                </Tooltip>
              ) : row.fecha_cierre_real ? (
                "-"
              ) : (
                <div style={{ color: "#db2828" }} title={t("frontend.auditoria.lb_noleader_warning")}>
                  <i className='icon exclamation triangle red' /> -
                </div>
              )
            }
          },
          evaluation: {
            headerCell: t("frontend.evaluacion.__name__"),
            cell: (row) => {
              return <EvaluationCell auditoriaData={row} />
            }
          },
          status: {
            headerCell: <div>{t("frontend.default.status")}</div>,
            cell: (row) => {
              const status = mapAuditStatus(row.status)
              if (row) {
                if (row?.fecha_cierre_real !== null) {
                  return (
                    <>
                      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        <Pill color={status.color} />
                        <span>{t("frontend.auditoria.fields.status__choices.CERRADO")}</span>
                      </div>
                      <div style={{ fontSize: 12 }}>Fecha de Cierre: {dateFormatter(row?.fecha_cierre_real)} </div>
                    </>
                  )
                } else {
                  return (
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                      <Pill color={status.color} />
                      <span>{t(`frontend.auditoria.fields.status__choices.${status.value}`, status.value)}</span>
                    </div>
                  )
                }
              }
            }
          },
          actions: {
            headerCell: null,
            cell: (row) => {
              return <Actions row={row} onActionClick={onAction.bind(this, row)} />
            },
            showOnHover: true
          }
        },
        expandedDetails: (el) => {
          return {
            enabled: el.configs?.enable_sprints == "1",
            render: () => {
              return (
                <div className={styles.expandedRow}>
                  <div className='ui sub header'>{t("frontend.auditoria.sprint.__list__long")}</div>
                  <SprintSimpleTableList auditId={el.id} />
                </div>
              )
            }
          }
        }
      }}
      topLeftContent={
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <span>{t("main_ui.general.lb_conventions")}:</span>

          {Object.entries(auditStatus).map(([key, status]) => {
            return (
              <div key={status.value} style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <Pill color={status.color} height={16} width={8} />
                <span>{t(`frontend.auditoria.fields.status__choices.${status.value}`, status.value)}</span>
              </div>
            )
          })}
        </div>
      }
    />
  )
}
