import React from "react"
import ErrorBoundary from "src/errors/ErrorBoundary"
import styles from "./style.module.scss"

export default function AdvancedWizardContent({ children, status, data }) {
  const getClass = () => {
    let nameClass = undefined

    switch (status) {
      case "left":
        nameClass = styles.left
        break
      case "right":
        nameClass = styles.right
        break
      case "active":
        nameClass = styles.active
        break
    }

    return nameClass
  }

  return (
    <div className={`${styles.step} ${getClass()}`}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  )
}
