import React from "react"
import Loading from "src/components/Loading"
import { auditStatus } from "src/constants/audit"
import Kanban from "../../components/Kanban"
import styles from "./style.module.scss"

export default function KanbanSection({ data, cardContent, postponedData = null }) {
  return (
    <div>
      <Kanban
        data={[].concat(data, postponedData)}
        displayedColumns={[
          auditStatus.NO_STARTED.value,
          auditStatus.IN_PROCESS.value,
          auditStatus.CONCLUDED.value,
          auditStatus.DISCONTINUED.value,
          auditStatus.POSTPONED.value
        ]}
        config={{
          columns: {
            [auditStatus.NO_STARTED.value]: {
              color: auditStatus.NO_STARTED.color,
              headerCell: (itemsAmount) => {
                return (
                  <div className={styles.header}>
                    <span>{auditStatus.NO_STARTED.label}</span>
                    <span className={styles.subtitle}>{itemsAmount} Auditoría(s)</span>
                  </div>
                )
              },
              cell: (row) => cardContent(row, auditStatus.NO_STARTED)
            },
            [auditStatus.IN_PROCESS.value]: {
              color: auditStatus.IN_PROCESS.color,
              headerCell: (itemsAmount) => {
                return (
                  <div className={styles.header}>
                    <span>{auditStatus.IN_PROCESS.label}</span>
                    <span className={styles.subtitle}>{itemsAmount} Auditoría(s)</span>
                  </div>
                )
              },
              cell: (row) => cardContent(row, auditStatus.IN_PROCESS)
            },
            [auditStatus.CONCLUDED.value]: {
              color: auditStatus.CONCLUDED.color,
              headerCell: (itemsAmount) => {
                return (
                  <div className={styles.header}>
                    <span>{auditStatus.CONCLUDED.label}</span>
                    <span className={styles.subtitle}>{itemsAmount} Auditoría(s)</span>
                  </div>
                )
              },
              cell: (row) => cardContent(row, auditStatus.CONCLUDED)
            },
            [auditStatus.DISCONTINUED.value]: {
              color: auditStatus.DISCONTINUED.color,
              headerCell: (itemsAmount) => {
                return (
                  <div className={styles.header}>
                    <span>{auditStatus.DISCONTINUED.label}</span>
                    <span className={styles.subtitle}>{itemsAmount} Auditoría(s)</span>
                  </div>
                )
              },
              cell: (row) => cardContent(row, auditStatus.DISCONTINUED)
            },
            [auditStatus.POSTPONED.value]: {
              color: auditStatus.POSTPONED.color,
              headerCell: (itemsAmount) => {
                return (
                  <div className={styles.header}>
                    <span>{auditStatus.POSTPONED.label}</span>
                    <span className={styles.subtitle}>{itemsAmount} Auditoría(s)</span>
                  </div>
                )
              },
              beforeCell: () =>
                postponedData === null ? <Loading type={"SyncLoader"} size={12} margin={5} centered={false} /> : null,
              cell: (row) => cardContent(row, auditStatus.POSTPONED)
            }
          },
          filterBy: "status"
        }}
      />
      {data == null && <Loading text='Cargando auditorías...' curtain={false} />}
    </div>
  )
}
