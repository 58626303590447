import { useEffect, useState } from "react"
import Icon from "../Icon"
import AdvancedWizardContent from "./AdvancedWizardContent"
import AdvancedWizardHeader from "./AdvancedWizardHeader"
import styles from "./style.module.scss"

export default function AdvancedWizard({
  initSelected = 0,
  onStepEnter = () => {},
  onStepLeave = () => {},
  showHeader = true,
  config = {},
  displaySteps = [],
  maxSteps = null,
  headerStyle = {}
}) {
  const [stepSelected, setStepSelected] = useState(initSelected)
  const [displayed, setDisplayed] = useState([initSelected])

  const onGoToHandler = (toIndex) => {
    setStepSelected(toIndex)
    if (!displayed.includes(toIndex)) {
      setDisplayed((prev) => {
        const updatedData = [...prev]
        updatedData.push(toIndex)
        return updatedData
      })
    }
    onStepLeave({ from: stepSelected, to: toIndex })
  }

  useEffect(() => {
    onStepEnter(stepSelected)
  }, [stepSelected])

  const renderHeader = () => {
    if (showHeader) {
      const headerWrapperStyle =
        maxSteps == null ? {} : { display: "grid", gridTemplateColumns: `repeat(${maxSteps}, 1fr)` }
      return (
        <div style={headerWrapperStyle} className={styles.wizardHeaderWrapper}>
          {displaySteps.map((key, index) => (
            <AdvancedWizardHeader
              isAlone={displaySteps.length === 1}
              key={index}
              isActive={index === stepSelected}
              isLast={displaySteps.length === index + 1}
              style={headerStyle}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 15,
                  fontWeight: "bold"
                }}
              >
                {index < stepSelected ? (
                  <Icon name='check' color='#a8cf45' />
                ) : (
                  <span style={{ fontSize: 20 }}>{index + 1}</span>
                )}
                {config[key].headerLabel}
              </div>
            </AdvancedWizardHeader>
          ))}
        </div>
      )
    } else {
      return <></>
    }
  }
  const renderContent = () => (
    <div className={styles.wizardContentWrapper}>
      {displaySteps.map((key, index) => {
        if (!displayed.includes(index)) {
          return null
        }
        let status = ""

        if (index < stepSelected) {
          status = "left"
        } else if (index === stepSelected) {
          status = "active"
        } else {
          status = "right"
        }

        return (
          <AdvancedWizardContent key={index} status={status}>
            {config[key].content({
              goBack: (_index = index - 1) => {
                onGoToHandler(_index)
              },
              goNext: (_index = index + 1) => {
                onGoToHandler(_index)
              },
              status
            })}
          </AdvancedWizardContent>
        )
      })}
    </div>
  )

  return (
    <div className={styles.wizard}>
      {renderHeader()}
      {renderContent()}
    </div>
  )
}
