import Link from "next/link"
import { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { getCertifications } from "src/services/certifications.service"
export default function CapacCertificateAlert() {
  const [count, setCount] = useState(null)

  useEffect(() => {
    getCertifications({ viewed: "0", _retval: "c" }).then(({ data }) => {
      setCount(data.count)
    })
  }, [])

  if (count === null || count == 0) {
    return null
  }

  return (
    <div className='ui message orange' style={{ cursor: "pointer" }}>
      <Trans i18nKey='frontend.capac_certificate.messages.pending_to_view__message' count={count}>
        <>
          Tienes
          <div className='ui label circular red' style={{ margin: "0 5px 0 5px" }}>
            {{ count }}
          </div>
          certificados pendientes por revisar.
        </>
      </Trans>
      <Link href={{ pathname: "/certificaciones" }}>
        <a className='ui button tertiary'>
          <Trans i18nKey={"frontend.capac_certificate.btn_goto_page"} />
        </a>
      </Link>
    </div>
  )
}
