export const getUrlExportLink = (params) => {
  return getDownloadUrlLink("general/export", params)
}

export const getUrlPublicDownloadFile = (file) => {
  const token = localStorage.getItem("token")

  return `${process.env.apiUrl}/general/public/?token=${token}&f=${encodeURIComponent(file)}`
}

export const getDownloadUrlLink = (url, params = {}) => {
  params["token"] = localStorage.getItem("token")
  params = Object.entries(params)
    .filter(([key, value]) => value !== null)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join("&")

  if (url.startsWith("/")) {
    url = url.substr(1)
  }

  return `${process.env.apiUrl}/${url}/?${params}`
}
