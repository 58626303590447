export default function trim(value = '', characters = [' ']) {
  const charactersMap = characters.reduce((agg, c) => {
    agg[c] = true
    return agg
  }, {})

  let trimStart = 0
  let trimEnd = value.length

  for (let i = 0; i < value.length; i++) {
    const character = value[i]

    if (!(character in charactersMap)) {
      trimStart = i
      break
    }
  }

  for (let i = value.length - 1; i > -1; i--) {
    const character = value[i]

    if (!(character in charactersMap)) {
      trimEnd = i + 1
      break
    }
  }

  return value.substring(trimStart, trimEnd)
}
