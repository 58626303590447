import cx from "classnames"
import { useContext } from "react"
import { RadioGroupContext } from ".."
import styles from "./style.module.scss"
import useConfirmationModal from "src/hooks/useConfirmationModal"

export default function RadioButton({ children, value, style, className, color = "#0a858d", data = null }) {
  const { radioSelected, setRadioSelected, onRadioChange, disabled, readOnly } = useContext(RadioGroupContext)

   const { openConfirmationModal } = useConfirmationModal({
    title: "¿ Está seguro de que NO requiere un Plan de Acción ? Tenga en cuenta que este hallazgo tiene un Plan de Acción en proceso.",
    buttons: {
      left: {
        text: "No",
        name: "normal"
      },
      right: {
        text: "Sí",
        name: "warning"
      }
    }
  })

  const onChangeHandler = () => {
    if (disabled || readOnly) return

    if (radioSelected === value) {
      return
    }

    if(value === "NO_PLAN_DE_ACCION" && data.plan_accion.status !== "NUEVO"){
      openConfirmationModal({
        onSuccess: () => {
          onRadioChange(value)
          setRadioSelected(value)
        }
      })
    }
    else{
      onRadioChange(value)
      setRadioSelected(value)
    }    
  }

  return (
    <div className={cx(styles.radio, className)} style={{ ...style }}>
      <label
        className={cx(styles.radioLabel, readOnly && styles.readOnly)}
        style={{
          cursor: disabled ? "not-allowed" : "",
          opacity: disabled ? 0.7 : ""
        }}
        onClick={onChangeHandler}
      >
        <div className={styles.radioContainer}>
          <div
            className={styles.radioOuterCircle}
            style={{
              backgroundColor: `${radioSelected === value ? (color ? color : color) : "#fff"}`
            }}
          />
        </div>
        {children && <div className={styles.radioLabelContent}>{children}</div>}
      </label>
    </div>
  )
}
