function _parse(data) {
  if (data.length > 0) {
    return data.map((item) => {
      return {
        id: item.id,
        date: item.fecha,
        hour: item.hora,
        name: item.nombre,
        organizer: item.usuario_asignado.name,
        organizer_sigla: item.usuario_asignado.sigla,
        description: item.descripcion
      }
    })
  } else {
    return []
  }
}

export default function parse(data) {
  return new Promise((resolve) =>
    setTimeout(() => {
      const parsed = _parse(data)

      resolve(parsed)
    }, 0)
  )
}
