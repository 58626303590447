import cx from "classnames"
import React, { useEffect, useState } from "react"
import RadioButton from "./RadioButton"
import styles from "./style.module.scss"

export const RadioGroupContext = React.createContext(null)

export default function RadioGroup({
  children,
  onRadioChange,
  color,
  initialValue,
  disabled = false,
  readOnly = false,
  className = null,
  choices = null,
  layout = "grid",
  style = {}
}) {
  const [radioSelected, setRadioSelected] = useState(initialValue)

  useEffect(() => {
    setRadioSelected(initialValue)
  }, [initialValue])

  const renderContent = () => {
    if (choices) {
      return choices.map((choice) => {
        return (
          <RadioButton key={choice.value} value={choice.value} className={choice.className}>
            {choice.label}
          </RadioButton>
        )
      })
    }

    return children
  }

  return (
    <RadioGroupContext.Provider
      value={{
        radioSelected,
        setRadioSelected,
        color,
        initialValue,
        onRadioChange,
        disabled,
        readOnly
      }}
    >
      <div
        className={cx(
          layout == "grid" ? styles.radioGroupGrid : styles.radioGroup,
          (disabled || readOnly) && styles.disabled,
          className
        )}
        style={style}
      >
        {renderContent()}
      </div>
    </RadioGroupContext.Provider>
  )
}

RadioGroup.Choice = RadioButton
