import cx from "classnames"
import React, { createContext, useEffect, useMemo, useReducer } from "react"
import ErrorBoundary from "src/errors/ErrorBoundary"
import { UserIcon } from "../UserCardInfo"
import SidenavOption from "./SidenavOption"
import styles from "./style.module.scss"

const parseByType = function (el) {
  let predefined = {}
  switch (el.type) {
    case "export_excel":
      predefined = {
        tooltip: "export_as_excel",
        icon: "sidenav/file-excel-regular"
      }
      break
    case "export_pdf":
      predefined = {
        tooltip: "export_as_pdf",
        icon: "sidenav/export-as-pdf"
      }
      break
    case "export_word":
      predefined = {
        tooltip: "export_as_word",
        icon: "sidenav/export-as-word"
      }
      break
    case "import":
      predefined = {
        tooltip: "export_as_word",
        icon: "sidenav/import"
      }

      break
  }

  return { ...predefined, ...el }
}

export const SidenavContext = createContext({
  sidenavItems: null,
  manageSidenavItems: () => {}
})

const sidenavReducer = (state, action) => {
  switch (action.type) {
    case "CLEAR":
      return null
    case "SET":
      return action.value
    case "UPDATE":
      return state?.map((x) => {
        return x.id == action.id ? { ...x, ...action.value } : x
      })
  }
}

export default function SidenavPage({ children, sidenav, style = {}, wrapperStyle = {}, disabled = false }) {
  const [sidenavItems, dispatch] = useReducer(sidenavReducer, sidenav ?? [])

  const manageSidenavItems = {
    set(value) {
      dispatch({ type: "SET", value })
    },

    updateItem(id, value) {
      dispatch({ type: "UPDATE", id, value })
    },

    clear() {
      dispatch({type: 'CLEAR'})
    },

    dispatch
  }

  const contextValue = useMemo(
    () => ({
      sidenavItems,
      manageSidenavItems
    }),
    [sidenavItems]
  )

  useEffect(() => {
    if (typeof sidenav !== "undefined") {
      dispatch({ type: "SET", value: sidenav })
    }
  }, [sidenav])

  if (disabled) {
    return children
  }

  const renderSidenav = () => {
    if (!sidenavItems) {
      return null
    }

    if (React.isValidElement(sidenavItems)) {
      return sidenavItems
    }
    if (Array.isArray(sidenavItems)) {
      return sidenavItems.map((el, idx) => {
        if (!el) {
          return null
        }

        return React.isValidElement(el) ? (
          <React.Fragment key={idx}>{el}</React.Fragment>
        ) : typeof el.hidden === "undefined" || !el.hidden ? (
          <SidenavOption key={idx} {...parseByType(el)} />
        ) : null
      })
    }

    return sidenavItems
  }

  const nosidebar = !sidenavItems?.length

  return (
    <div className={cx(styles.sidenavContainer, !nosidebar ? "sidenavContainer sided-nav" : "nosidebar")} style={style}>
      <SidenavContext.Provider value={contextValue}>
        <div className={cx(styles.sidenavContentWrapper, "sidenavContentWrapper")}>{children}</div>
      </SidenavContext.Provider>
      {!nosidebar && (
        <div className={styles.sidenavWrapper} style={{ width: 46, ...wrapperStyle }}>
          <UserIcon />
          <div style={{ paddingTop: 52 }}>
            <ErrorBoundary>{renderSidenav()}</ErrorBoundary>
          </div>
        </div>
      )}
    </div>
  )
}

export const useSidenavPage = () => React.useContext(SidenavContext)
