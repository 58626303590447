import compareAsc from "date-fns/compareAsc"
import { useEffect, useState } from "react"
import { dateParser } from "src/helpers/dateFormatter"
import SingleDatePicker from "../../components/SingleDatePicker"
import styles from "./styles.module.scss"

export default function RangeDatePicker({
  format,
  initialStartDateValue = null,
  initialEndDateValue = null,
  startChange = ([valueAsDate, valueAsStr]) => {},
  endChange = ([valueAsDate, valueAsStr]) => {},
  labelConfig = {
    start: "Fecha Inicial",
    end: "Fecha Final",
    position: "left",
    bold: true,
    twoPoints: true,
    style: { width: 140 }
  },
  startDatepickerProps = {},
  endDatepickerProps = {},
  errorsFormStart = false,
  errorsFormEnd = false,
  disabled = false
}) {
  const [hasError, setHasError] = useState(false)

  const [startDate, setStartDate] = useState(initialStartDateValue ? initialStartDateValue : null)
  const [endDate, setEndDate] = useState(initialEndDateValue ? initialStartDateValue : null)

  useEffect(() => {
    const date = initialStartDateValue ? initialStartDateValue : null
    setStartDate(date)
  }, [initialStartDateValue])

  useEffect(() => {
    const date = initialEndDateValue ? initialEndDateValue : null

    setEndDate(date)
  }, [initialEndDateValue])

  const onStartChangeHandler = ([valueAsDate, valueAsStr]) => {
    setStartDate(valueAsStr)
    startChange([valueAsDate, valueAsStr])
  }

  const onEndChangeHandler = ([valueAsDate, valueAsStr]) => {
    setEndDate(valueAsStr)
    endChange([valueAsDate, valueAsStr])
  }

  useEffect(() => {
    if (
      typeof startDate === "undefined" ||
      startDate === null ||
      startDate === "" ||
      typeof endDate === "undefined" ||
      endDate === null ||
      endDate === ""
    ) {
      return
    }

    const _start = dateParser(startDate)
    const _end = dateParser(endDate)

    const isStartAfterEnd = compareAsc(_start, _end) === 1

    if (isStartAfterEnd) {
      setHasError(true)
    } else {
      setHasError(false)
    }
  }, [startDate, endDate])

  return (
    <>
      <SingleDatePicker
        value={startDate}
        format={format}
        positionLabel={labelConfig.position}
        bold={labelConfig.bold}
        color='#424242'
        twoPoints={labelConfig.twoPoints}
        inputWrapperStyle={{ ...labelConfig.style }}
        label={labelConfig.start}
        onChange={onStartChangeHandler}
        errorsForm={errorsFormStart}
        disabled={disabled}
        {...startDatepickerProps}
      />
      <SingleDatePicker
        value={endDate}
        format={format}
        positionLabel={labelConfig.position}
        bold={labelConfig.bold}
        color='#424242'
        twoPoints={labelConfig.twoPoints}
        inputWrapperStyle={{ ...labelConfig.style }}
        label={labelConfig.end}
        onChange={onEndChangeHandler}
        errorsForm={errorsFormEnd}
        disabled={disabled}
        {...endDatepickerProps}
      />

      {!hasError ? null : (
        <div className={styles.errorMessage}>La fecha fin no puede ser menor que la fecha de inicio</div>
      )}
    </>
  )
}
