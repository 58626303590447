import trim from './trim-str'

export default function orDefault(value = '', defaultValue = '') {
  if (typeof value === 'undefined' || value === null) {
    return defaultValue
  }

  value = trim(value, [' ', '\n', '\k'])

  if (value === '') {
    return defaultValue
  }

  return value
}
