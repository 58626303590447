import cx from "classnames"
import React, { useEffect, useState } from "react"
import { Checkbox } from "semantic-ui-react"
import Tooltip from "src/components/Tooltip"
import useTranslation from "src/hooks/useTranslation"
import styles from "./CreateRiskFromProject.module.scss"

const OptionCheckbox = ({ option, value, onChangeCheckbox, disabled = false, checkDependencies = false }) => {
  const { t } = useTranslation()

  const isReadonly = disabled || (option.readOnly ?? false)

  return (
    <div className={cx("chk", styles.chk, { readonly: isReadonly, disabled })}>
      <span
        onClick={() => (isReadonly ? null : onChangeCheckbox(option.name, checkDependencies)(null, { checked: false }))}
        className={cx(styles.lb, styles.lb_no)}
      >
        {t("main_ui.general.lb_no")}
      </span>{" "}
      <Tooltip
        inline
        title={t("main_ui.general.disabled_option_title")}
        disabled={!disabled}
        content={disabled ? t("frontend.form.copy_options.lb_depends_on", { depends: t(disabled) }) : null}
      >
        <Checkbox
          toggle
          disabled={isReadonly}
          checked={option.name === false ? true : !!value[option.name]}
          onChange={isReadonly ? () => {} : onChangeCheckbox(option.name, checkDependencies)}
        />
      </Tooltip>{" "}
      <span
        onClick={() => (isReadonly ? null : onChangeCheckbox(option.name, checkDependencies)(null, { checked: true }))}
        className={cx(styles.lb, styles.lb_yes)}
      >
        {t("main_ui.general.lb_yes")}
      </span>
    </div>
  )
}

const CopyOptions = ({ typeTree, projectType, schema, onSelect, defaultValue = null }) => {
  const [value, setValue] = useState(() => {
    if (defaultValue === null) {
      defaultValue = {}
      schema?.forEach((x) => {
        if (x.name && x.default) {
          defaultValue[x.name] = x.default
        }
        x.items?.forEach((y) => {
          if (y.name && y.default) {
            defaultValue[y.name] = y.default
          }
        })
      })
    }

    return defaultValue
  })

  const { t } = useTranslation()

  useEffect(() => {
    onSelect(value)
  }, [value])

  const findDependencies = (name) => {
    const found = schema.find((x) => x.name == name)
    return found ? found.items ?? [] : []
  }

  const getItemsToRender = (item) => {
    if (item.name && !value[item.name]) {
      return []
    }

    return item.items ?? []
  }

  const onSelectAll = (check) => () => {
    if (check) {
      const values = {}
      schema.forEach((x) => {
        if (x.name) {
          values[x.name] = true
        }
        if (x.items) {
          x.items.forEach((y) => {
            if (y.name) {
              values[y.name] = true
            }
          })
        }
      })
      setValue(values)
    } else if (schema.length == 1) {
      const values = {}
      schema[0].items?.forEach((y) => {
        if (y.name && (y.readOnly ?? false)) {
          values[y.name] = true
        }
      })
      setValue(values)
    } else {
      setValue({})
    }
  }

  const checkIsDisabled = (option) => {
    if (typeof option.depends === "undefined") {
      return false
    }

    const missing = option.depends.find((x) => !value[x])

    if (typeof missing === "undefined") {
      return false
    }

    return schema.find((x) => x.name == missing)?.label
  }

  const onChangeCheckbox =
    (name, checkDependencies = false) =>
    (ev, data) => {
      setValue((prevValue) => {
        const updated = { ...prevValue }
        if (data.checked) {
          updated[name] = true
        } else {
          delete updated[name]
        }
        if (checkDependencies && !data.checked) {
          findDependencies(name).forEach((x) => {
            delete updated[x]
          })

          schema.forEach((x) => {
            if (typeof x.depends !== "undefined" && x.depends.includes(name)) {
              delete updated[x.name]
            }
          })
        }

        return updated
      })
    }

  return (
    <table className={cx("ui table compact", styles.table)}>
      <thead>
        <tr>
          <th>{t("frontend.form.lb_item")}</th>
          <th style={{ width: 150 }}>{t("frontend.form.copy_options.lb_add_to_copy")}</th>
        </tr>
      </thead>
      <tbody>
        {schema?.map((x, k) => {
          if (x.projectType && x.projectType != projectType) {
            return null
          }
          if (x.typeTree && x.typeTree != typeTree) {
            return null
          }

          return (
            <React.Fragment key={k}>
              <tr className={styles.tRowGroup}>
                <td className='lb'>{t(x.label)}</td>
                <td>
                  {x.name && (
                    <div className='chk'>
                      <OptionCheckbox
                        option={x}
                        value={value}
                        onChangeCheckbox={onChangeCheckbox}
                        disabled={checkIsDisabled(x)}
                        checkDependencies
                      />
                    </div>
                  )}
                </td>
              </tr>
              {getItemsToRender(x).map((y, j) => (
                <tr key={`${k}-${j}`}>
                  <td>
                    {t(y.label)}
                    {y.readOnly && (
                      <span style={{ color: "red", fontWeight: 500 }}>
                        {t("frontend.form.copy_options.lb_copy_auto_added")}
                      </span>
                    )}
                  </td>
                  <td>
                    <OptionCheckbox option={y} value={value} onChangeCheckbox={onChangeCheckbox} />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={2}>
            <div className={cx("ui horizontal list", styles.barActions)}>
              <div className='item'>
                <a onClick={onSelectAll(true)}>{t("main_ui.general.btn_select_all")}</a>
              </div>
              <span className='item'>|</span>
              <div className='item'>
                <a onClick={onSelectAll(false)}>{t("main_ui.general.btn_select_none")}</a>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export const CopyOptionsForFormModalAdapter = ({ disabled, initialValue, onChange, schema, typeTree, projectType }) => {
  return (
    <CopyOptions
      typeTree={typeTree}
      projectType={projectType}
      defaultValue={initialValue}
      onSelect={onChange}
      disabled={disabled}
      schema={schema}
    />
  )
}

export default CopyOptions
