import endOfYear from "date-fns/endOfYear"
import startOfYear from "date-fns/startOfYear"
import { dateFormatter } from "src/helpers/dateFormatter"

export const weekdaysShort = {
  es: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"]
}

export const monthsLocale = {
  es: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ]
}

export const weekdaysLong = {
  es: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
}

export const firstDayOfWeek = {
  es: 1
}
// Translate aria-labels
export const labelsDayPicker = {
  es: { nextMonth: "Próximo mes", previousMonth: "Mes Anterior" }
}

export const months = Object.freeze({
  JANUARY: {
    id: 0,
    label: "Enero"
  },
  FEBRUARY: {
    id: 1,
    label: "Febrero"
  },
  MARCH: {
    id: 2,
    label: "Marzo"
  },
  APRIL: {
    id: 3,
    label: "Abril"
  },
  MAY: {
    id: 4,
    label: "Mayo"
  },
  JUNE: {
    id: 5,
    label: "Junio"
  },
  JULY: {
    id: 6,
    label: "Julio"
  },
  AUGUST: {
    id: 7,
    label: "Agosto"
  },
  SEPTEMBER: {
    id: 8,
    label: "Septiembre"
  },
  OCTOBER: {
    id: 9,
    label: "Octubre"
  },
  NOVEMBER: {
    id: 10,
    label: "Noviembre"
  },
  DECEMBER: {
    id: 11,
    label: "Diciembre"
  }
})

export const daysOfCurrentYear = new (class {
  get FIRST_DAY_YEAR() {
    return dateFormatter(startOfYear(new Date()))
  }

  get LAST_DAY_YEAR() {
    return dateFormatter(endOfYear(new Date()))
  }
})()

export const daysFromYear = (year) => ({
  FIRST_DAY_YEAR: dateFormatter(startOfYear(new Date(`${year}/01/01`))),
  LAST_DAY_YEAR: dateFormatter(endOfYear(new Date(`${year}/12/31`)))
})
