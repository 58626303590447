import Icon from "src/components/Icon"
import Tooltip from "src/components/Tooltip"
import { auditStatus } from "src/constants/audit"
import { dateFormatter } from "src/helpers/dateFormatter"
import orDefault from "src/helpers/default-str"
import formatMessage from "src/helpers/formatMessageDisabled"
import styles from "./style.module.scss"

export default function KanbanCard({
  data: { id, endDate, name, status, progress, leader, auditType, header, footer },
  color = "green",
  onArrowClick = () => {}
}) {
  return (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}>
          {header}
          <div className={styles.wrapper}>
            <span className={styles.title}>
              {id}-{name}
            </span>
          </div>

          <div className={styles.wrapper}>
            <span className={styles.title}>Fecha final:</span>
            <span>{dateFormatter(endDate)}</span>
          </div>
          <div className={styles.wrapper}>
            <span className={styles.title}>Avance:</span>
            <span>{progress}%</span>
          </div>

          <div className={styles.wrapper}>
            <span className={styles.title}>Auditor:</span>
            <span>{orDefault(leader, "Sin Asignar")}</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {onArrowClick === false ? null : (
            <div
              onClick={onArrowClick}
              style={{
                borderRadius: "50%",
                backgroundColor: color,
                display: "flex",
                height: 20,
                width: 20,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
            >
              <Tooltip
                content={
                  auditStatus.DISCONTINUED.value === status ? (
                    formatMessage({
                      type: "disabled",
                      button: "Ingresar",
                      motive: "La auditoría se encuentra en estado Anulado"
                    })
                  ) : (
                    <h3>Ingresar</h3>
                  )
                }
                style={{ maxWidth: 260 }}
              >
                <div style={{ transform: "rotateZ(-90deg)" }}>
                  <Icon size={10} color='#fff' name='arrow' />
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {footer}
    </div>
  )
}
