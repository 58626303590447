import cx from "classnames"
import { Fragment, useContext } from "react"
import { ButtonToggleGroupContext } from ".."
import Tooltip from "../../Tooltip"
import styles from "./style.module.scss"

export default function ButtonToggle({ children, tooltip, value, className = null }) {
  const { buttonToggleSelected, setButtonToggleSelected, onButtonToggleChange } = useContext(ButtonToggleGroupContext)

  const Wrapper = tooltip ? Tooltip : Fragment

  const onClickHandler = () => {
    if (buttonToggleSelected === value) {
      return
    }

    onButtonToggleChange(value)
    setButtonToggleSelected(value)
  }

  return (
    <Wrapper content={tooltip}>
      <button
        className={cx(className, styles.buttonToggle, buttonToggleSelected === value && [styles.buttonToggleActive])}
        onClick={onClickHandler}
      >
        {children}
      </button>
    </Wrapper>
  )
}
