import addDays from "date-fns/addDays"
import addMonths from "date-fns/addMonths"
import compareAsc from "date-fns/compareAsc"
import endOfMonth from "date-fns/endOfMonth"
import endOfYear from "date-fns/endOfYear"
import startOfMonth from "date-fns/startOfMonth"
import startOfYear from "date-fns/startOfYear"
import subDays from "date-fns/subDays"
import subMonths from "date-fns/subMonths"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { dateFormatter, dateParser } from "src/helpers/dateFormatter"
import SingleDatePicker from "../../SingleDatePicker"

export default function GanttDateFilters(
  {
    min = "",
    max = "",
    defaultStart = "",
    defaultEnd = "",
    onChange = () => {
    },
    onErrorDisplay = () => {
    }
  }
) {
  const [start, setStart] = useState([])
  const [end, setEnd] = useState([])

  const [hasError, setHasError] = useState(false)

  // -1 means the user has NOT selected any input first
  // 0 means the user has selected the start date first
  // 1 means the user has selected the end date first
  const [first, setFirst] = useState(-1)

  const readDate = useCallback((tuple = [], position = "") => {
    if (!Array.isArray(tuple)) {
      return null
    }

    const [dateObj = null, dateStr = null] = tuple

    switch (position) {
      case "date": {
        return dateObj
      }

      case "str": {
        return dateStr
      }
    }

    return null
  }, [])

  const rootRange = useMemo(() => {
    const result = {
      min: startOfYear(new Date()),
      max: endOfYear(new Date())
    }

    if (typeof min === "string" && min !== "") {
      const parsedMin = dateParser(min)

      if (compareAsc(result.min, parsedMin) === 1) {
        result.min = parsedMin
      }
    }

    if (typeof max === "string" && max !== "") {
      const parsedMax = dateParser(max)

      if (compareAsc(result.max, parsedMax) === -1) {
        result.max = parsedMax
      }
    }

    return result
  }, [min, max])

  useEffect(() => {
    if (typeof defaultStart === "string" && defaultStart !== "") {
      setStart([dateParser(defaultStart), defaultStart])
    } else {
      setStart([rootRange.min, dateFormatter(rootRange.min)])
    }

    if (typeof defaultEnd === "string" && defaultEnd !== "") {
      setEnd([dateParser(defaultEnd), defaultEnd])
    } else {
      setEnd([rootRange.max, dateFormatter(rootRange.max)])
    }
  }, [defaultStart, defaultEnd, rootRange])

  useEffect(() => {
    const startStr = readDate(start, "str")
    const endStr = readDate(end, "str")

    if (startStr === null || endStr === null) {
      return
    }

    onChange({
      start: startStr,
      end: endStr
    })
  }, [start, end, onChange, readDate])

  useEffect(() => {
    const _start = readDate(start, 'date')
    const _end = readDate(end, 'date')

    if (_start === null || _end === null) {
      return
    }

    const isStartAfterEnd = compareAsc(_start, _end) === 1

    setHasError(isStartAfterEnd)
    onErrorDisplay(isStartAfterEnd)
  }, [start, end, onErrorDisplay])

  const filterProps = useMemo(() => {
    // // -1 means the user has NOT selected any input first
    if (first === -1) {
      return {
        start: {
          min: dateFormatter(rootRange.min),
          max: dateFormatter(rootRange.max)
        },

        end: {
          min: dateFormatter(rootRange.min),
          max: dateFormatter(rootRange.max)
        }
      }
    }

    let diff

    // 0 means the user has selected the start date first
    if (first === 0) {
      const startDate = readDate(start, "date")
      const tempMax = endOfMonth(addMonths(startDate, 11))
      diff = compareAsc(tempMax, rootRange.max)
      const rootMax = diff === 1 ? rootRange.max : tempMax

      return {
        start: {
          min: dateFormatter(rootRange.min),
          max: dateFormatter(rootRange.max)
        },

        end: {
          max: dateFormatter(rootMax),
          min: dateFormatter(addDays(startDate, 1))
        }
      }
    }

    // 1 means the user has selected the end date first
    const endDate = readDate(end, "date")
    const tempMin = startOfMonth(subMonths(endDate, 11))
    diff = compareAsc(tempMin, rootRange.min)
    const rootMin = diff === -1 ? rootRange.min : tempMin

    return {
      start: {
        min: dateFormatter(rootMin),
        max: dateFormatter(subDays(endDate, 1))
      },

      end: {
        min: dateFormatter(rootRange.min),
        max: dateFormatter(rootRange.max)
      }
    }
  }, [first, rootRange, readDate, start, end])

  return (
    <>
      <SingleDatePicker
        label="Fecha de inicio"
        positionLabel="top"
        value={readDate(start, "str")}
        onChange={([dateObj, dateStr]) => {
          setStart([dateObj, dateStr])

          if (first === -1) {
            setFirst(0)
          } else if (first === 1) {
            setFirst(-1)
          }
        }}
      />

      <SingleDatePicker
        label="Fecha de fin"
        positionLabel="top"
        value={readDate(end, "str")}
        onChange={([dateObj, dateStr]) => {
          setEnd([dateObj, dateStr])

          if (first === -1) {
            setFirst(1)
          } else if (first === 0) {
            setFirst(-1)
          }
        }}
      />
    </>
  )
}
