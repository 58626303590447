import { useState } from "react"
import SidenavPage from "src/components/SidenavPage"
import { buildAuditBreadcumb } from "src/helpers/breadcrumbs"
import ContentLayout from "src/layouts/ContentLayout"
import { FirstStepVideo } from "src/macrocomponents/VideoFirstStep"
import useQueryString from "../hooks/useQueryString"
import AuditGeneralView from "../macrocomponents/AuditManagement/AuditGeneralView"

export default function Index() {
  const [auditYear, setAuditYear] = useState(null)

  const onQueryStringUpdate = (value) => {
    const currentYear = new Date().getFullYear()
    let selectedYear = new Date().getFullYear()

    if ("año" in value) {
      selectedYear = value["año"]
      if (isNaN(selectedYear)) {
        selectedYear = currentYear
      }
      localStorage.setItem("selectedYear", selectedYear)
    } else if (!!localStorage.getItem("selectedYear")) {
      selectedYear = localStorage.getItem("selectedYear")
      if (isNaN(selectedYear)) {
        selectedYear = currentYear
      }
    } else {
      localStorage.setItem("selectedYear", selectedYear.toString(10))
    }

    setAuditYear(selectedYear)
  }

  useQueryString(["año"], onQueryStringUpdate, {}, false, true)

  if (!auditYear) return null

  return (
    <SidenavPage>
      <ContentLayout pageTitle='auditoria__plan' breadCrumbConfig={buildAuditBreadcumb(null, [], "plan")}>
        <AuditGeneralView auditYear={auditYear} />
        <FirstStepVideo videoId='619509514' />
      </ContentLayout>
    </SidenavPage>
  )
}
