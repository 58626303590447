import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getAuditCommittee = authValidationMiddleware(async (year) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria_comite/?ano_auditoria=${year}`)

  return response.data
})

export const addCommittee = authValidationMiddleware(async ({ payload }) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/auditoria_comite/`, payload)

  return response.data
})

export const updateCommittee = authValidationMiddleware(async ({ committeeId, body }) => {
  const response = await axiosApiInstance.put(`/proyecto/riesgo/auditoria_comite/${committeeId}/`, {
    ...body
  })

  return response.data
})

export const trashCommittee = authValidationMiddleware(async (committeeId) => {
  const url = `/proyecto/riesgo/auditoria_comite/${committeeId}/`

  const response = await axiosApiInstance.delete(url)

  return response.data
})
