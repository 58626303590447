import { useEffect, useState } from "react"
import Button from "src/components/Button"
import Dialog from "src/components/Dialog"
import DialogBody from "src/components/Dialog/DialogBody"
import DialogHeader from "src/components/Dialog/DialogHeader"
import useTranslation from "src/hooks/useTranslation"
import { getUserEvent, postUserEvent } from "src/services/user.service"

export const VideoModal = ({ videoId, onClose, title = null }) => {
  return (
    <Dialog isOpen>
      <DialogHeader
        background='var(--primary-emerald-green)'
        color='white'
        align='center'
        buttonClose
        handleClose={onClose}
      >
        {title}
      </DialogHeader>
      <DialogBody background='white' color='#e6e6e6' align='center'>
        <iframe
          src={`https://player.vimeo.com/video/${videoId}`}
          width='640'
          height='480'
          frameBorder='0'
          allowFullScreen
          allow='autoplay; encrypted-media'
        ></iframe>
      </DialogBody>
    </Dialog>
  )
}

export const VideoModalButton = ({ videoId }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        name='orange'
        style={{ padding: "7px 5px", fontWeight: 600 }}
        text={
          <>
            <i className='icon circle play' />
            {t("frontend.general.watch_video_firststeps")}
          </>
        }
        onClick={() => setOpen(true)}
      />
      {open && (
        <VideoModal
          videoId={videoId}
          onClose={() => setOpen(false)}
          title={t("frontend.general.watch_video_firststeps")}
        />
      )}
    </>
  )
}

export const FirstStepVideo = ({ videoId }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (localStorage.getItem("firstStepsVideo") == "1") return

    getUserEvent("first_steps_video").then(({ data }) => {
      if (data.exists) localStorage.setItem("firstStepsVideo", "1")
      else setOpen(true)
    })
  }, [])

  const onClose = () => {
    setOpen(false)
    localStorage.setItem("firstStepsVideo", "1")
    postUserEvent("first_steps_video")
  }

  if (!open) return null

  return <VideoModal videoId={videoId} onClose={onClose} title={t("frontend.general.watch_video_firststeps")} />
}

export const NewStepsVideo = ({ videoId }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("newStepsAuditVideo") == "1") return

    getUserEvent("new_steps_audit_video").then(({ data }) => {
      if (data.exists) localStorage.setItem("newStepsAuditVideo", "1")
      else setOpen(true)
    })
  }, [])

  const onClose = () => {
    setOpen(false)
    localStorage.setItem("newStepsAuditVideo", "1")
    postUserEvent("new_steps_audit_video")
  }

  if (!open) return null

  return <VideoModal videoId={videoId} onClose={onClose} />
}
