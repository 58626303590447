import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { useCallback, useEffect, useReducer, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import Button from "src/components/Button"
import ButtonToggleGroup from "src/components/ButtonToggleGroup"
import ButtonToggle from "src/components/ButtonToggleGroup/ButtonToggle"
import CapacCertificateAlert from "src/components/CapacCertificateAlert"
import ExpirationAlert from "src/components/ExpirationAlert"
import EmpresaFilters from "src/components/Filter/EmpresaFilters"
import FormField from "src/components/FormField"
import Select from "src/components/FormField/Select"
import GanttDateFilters from "src/components/Gantt/GanttDateFilters"
import Icon from "src/components/Icon"
import InputSearch from "src/components/InputSearch"
import Loading from "src/components/Loading"
import { useSidenavPage } from "src/components/SidenavPage"
import { auditStatus } from "src/constants/audit"
import { daysFromYear } from "src/constants/date"
import { ShortISOFormat, dateFormatter, dateParser } from "src/helpers/dateFormatter"
import { debounce } from "src/helpers/debounce"
import { getUrlExportLink } from "src/helpers/export"
import { getListYears } from "src/helpers/years-list"
import useAccessControl from "src/hooks/useAccessControl"
import useAdvancedFilters from "src/hooks/useAdvancedFilters"
import useConfirm from "src/hooks/useConfirm"
import useConfirmationModal from "src/hooks/useConfirmationModal"
import useTranslation from "src/hooks/useTranslation"
import KanbanCard from "src/macrocomponents/AuditKanban/KanbanCard"
import FilterWarning from "src/macrocomponents/Tree/FilterWarning"
import {
  deleteAudit,
  getAuditsByYear,
  getPostponedAudits,
  revertPostponedAudit,
  updateAudit,
  updateFrequencyAudit
} from "src/services/audit.service"
import { getAuditCommittee } from "src/services/auditCommittee.service"
import { actionsAuditTypes, processTypeList } from "src/services/paramlists.service"
import { getUsers } from "src/services/user.service"
import useDispatch from "src/store"
import * as auditsActions from "src/store/audits/actions"
import KanbanSection from "../../AuditKanban"
import AuditListView from "../../AuditListView"
import { rowActions } from "../../AuditListView/Actions"
import AuditPostpone from "../../AuditPostpone"
import CreateAudit, { closeType } from "../../CreateAudit"
import parseCommittees from "../../ManageAuditingCommittee/Parser/CommiteeDataParser"
import styles from "./style.module.scss"

const content = Object.freeze({
  KANBAN: "KANBAN",
  LIST: "LIST",
  GANTT: "GANTT"
})

const PostponedRevertAction = ({ audit, onConfirm }) => {
  const { confirm, Confirm } = useConfirm()

  const { t } = useTranslation()

  const showConfirm = async () => {
    const isConfirmed = await confirm("¿Desea revertir el aplazamiento realizado a esta auditoría.?")
    if (isConfirmed) {
      onConfirm(audit)
    }
  }

  return (
    <div>
      <button className='ui button mini orange' onClick={showConfirm}>
        <i className='icon undo' /> {t("frontend.auditoria.actions.postpone.btn_undo")}
      </button>
      <Confirm />
    </div>
  )
}

const EditAudit = dynamic(() => import("../../EditAudit"), {
  loading: () => <Loading centered={false} />
})

const AuditoriaReporteExportModal = dynamic(() => import("./AuditoriaReporteExportModal"), {
  loading: () => <Loading centered={false} />
})

const ManageAuditingCommittee = dynamic(() => import("../../ManageAuditingCommittee"), {
  loading: () => <Loading centered={false} />
})

const AuditChangeToPrgFreq = dynamic(() => import("./AuditChangeToPrgFreq"), {
  loading: () => <Loading centered={false} />
})

const AuditBecomeTemplateWizard = dynamic(() => import("./AuditBecomeTemplateWizard"), {
  loading: () => <Loading centered={false} />
})

const AuditoriaGanttView = dynamic(
  () => import("src/macrocomponents/AuditManagement/AuditGeneralView/AuditoriaGanttView"),
  {
    loading: () => <Loading centered={false} />
  }
)

const actionModalReducer = (state, action) => {
  switch (action.type) {
    case "OPEN":
      return {
        modal: action.modal,
        auditData: action.auditData
      }
    case "CLOSE": {
      return false
    }
  }
}
export default function AuditGeneralView({ auditYear }) {
  const router = useRouter()
  const dispatch = useDispatch()
  const { hasPermission } = useAccessControl()

  const { manageSidenavItems } = useSidenavPage()

  const auditingCommittees = useSelector((state) => state.auditingCommittees.committees)

  const audits = useSelector((state) => state.audits.audits)

  const [contentKey, setContentKey] = useState(router?.query["view"] ?? content.LIST)

  const [startFilter, setStartFilter] = useState(() => {
    const year = Math.min(auditYear, new Date().getFullYear())
    return daysFromYear(year).FIRST_DAY_YEAR
  })
  const [endFilter, setEndFilter] = useState(() => {
    const year = Math.max(auditYear, new Date().getFullYear())
    return daysFromYear(year).LAST_DAY_YEAR
  })

  const [initialStartFilter, setInitialStartFilter] = useState(
    daysFromYear(
      getListYears()[0].label //getting older year
    ).FIRST_DAY_YEAR
  )
  const [initialEndFilter, setInitialEndFilter] = useState(daysFromYear(auditYear).LAST_DAY_YEAR)
  const [ganttDateError, setGanttDateError] = useState(false)

  const [auditOpened, setAuditOpened] = useState(false)
  const [postponedAudits, setPostponedAudits] = useState([])
  const [columnSort, setColumnSort] = useState(null)

  const [filterEmpresa, setFilterEmpresa] = useState(() => {
    let defaultValue = localStorage.getItem("ABI.auditoria.selectedCompany")

    if (defaultValue && String(defaultValue).includes("{")) {
      return null
    }

    return defaultValue ? defaultValue : null
  })
  const [filterUN, setFilterUN] = useState(() => {
    if (!filterEmpresa) {
      return null
    }
    const defaultValue = localStorage.getItem("ABI.auditoria.selectedBu")

    return defaultValue && defaultValue !== "null" ? defaultValue : null
  })

  const { t } = useTranslation()

  const { openConfirmationModal: openCloseModal } = useConfirmationModal({
    title: t("frontend.auditoria.btn_close"),
    question: "¿Está seguro que desea Cerrar esta auditoría?",
    rightButtonText: "Sí, Cerrar"
  })

  const { openConfirmationModal: openCancelModal } = useConfirmationModal({
    title: t("frontend.auditoria.btn_cancel"),
    question: t("frontend.auditoria.cancel__confirmation"),
    buttons: {
      left: { text: t("main_ui.general.lb_no"), name: "warning" },
      right: { text: "Sí, Anular", name: "normal" }
    }
  })

  const { openConfirmationModal: openDeleteModal } = useConfirmationModal({
    title: t("frontend.auditoria.btn_delete"),
    question: "¿Está seguro que desea eliminar esta auditoría?",
    buttons: {
      left: { text: t("main_ui.general.lb_no"), name: "normal" },
      right: { text: t("main_ui.general.lb_yes__delete"), name: "warning" }
    }
  })

  const { openConfirmationModal: reopenConfirmation } = useConfirmationModal({
    title: t("frontend.auditoria.btn_reopen"),
    question: "¿Está seguro de querer reabrir esta auditoría?",
    buttons: {
      left: { text: t("main_ui.general.lb_no"), name: "normal" },
      right: { text: "Sí, reabrir", name: "warning" }
    }
  })

  const { openConfirmationModal: changeToFreqEspConfirmation } = useConfirmationModal({
    title: t("frontend.auditoria.btn_changefreq", { freq: `$t(frontend.auditoria.fields.frequency__choices.ESP)` }),
    question: t("frontend.auditoria.btn_changefreq__confirmation", {
      freq: `$t(frontend.auditoria.fields.frequency__choices.ESP)`
    }),
    buttons: { left: { text: "No", name: "warning" }, right: { text: "lb_yes__proceed", name: "normal" } }
  })

  const [searchText, setSearchText] = useState("")
  const [paginatorData, setPaginatorData] = useState({
    currentPage: null,
    totalPages: null
  })

  const [auditSelected, setAuditSelected] = useState({})

  const [openManageAuditingCommittee, setOpenManageAuditingCommittee] = useState(false)

  const [yearOptions, setYearOptions] = useState(getListYears("DESCENDANT"))

  const [openActionModal, dispatchOpenActionModal] = useReducer(actionModalReducer, false)

  const AdvancedFilters = useAdvancedFilters({
    fields: [
      {
        label: "frontend.auditoria.fields.auditoria_tipo",
        componentType: "select",
        choices: actionsAuditTypes().GET,
        add_empty: true,
        name: "auditoria_tipo"
      },
      {
        label: "frontend.auditoria.fields.proceso_tipo",
        componentType: "select",
        choices: processTypeList,
        add_empty: true,
        name: "proceso_tipo"
      },
      {
        label: "frontend.auditoria.fields.leader",
        componentType: "select",
        choices: getUsers,
        name: "lider",
        add_empty: true,
        formatLabel: (x) => (x.id ? `${x.first_name} ${x.last_name} (${x.sigla})` : "")
      },
      {
        label: "frontend.auditoria.fields.frequency_type",
        componentType: "select",
        choices: [
          { id: "PRG", label: t("frontend.auditoria.fields.frequency__choices.PRG") },
          { id: "ESP", label: t("frontend.auditoria.fields.frequency__choices.ESP") }
        ],
        name: "tipo_frecuencia",
        add_empty: true
      },
      {
        label: "frontend.default.status",
        componentType: "select",
        choices: Object.values(auditStatus).map((x) => {
          return { id: x.value, label: t(`frontend.auditoria.fields.status__choices.${x.value}`) }
        }),
        name: "status",
        add_empty: true
      },
      {
        componentType: "custom_paramlists",
        name: "custom_paramlists",
        scope: "auditoria"
      }
    ]
  })

  const getData = async (page = 1, search = null) => {
    const extraArgs = {}

    if (typeof startFilter === "string" && startFilter !== "") {
      extraArgs.start = dateFormatter(dateParser(startFilter), ShortISOFormat)
    }

    if (typeof endFilter === "string" && endFilter !== "") {
      extraArgs.end = dateFormatter(dateParser(endFilter), ShortISOFormat)
    }

    if (filterEmpresa) {
      extraArgs.empresa = filterEmpresa
    }

    if (filterUN) {
      extraArgs.unidad_negocio = filterUN
    }

    if (contentKey == "GANTT") {
      extraArgs._sort_by = "fecha_inicio"
    } else if (columnSort) {
      extraArgs.sort_by = columnSort
    }

    if (search === null) {
      search = searchText
    }

    dispatch(auditsActions.populateAudits(null, auditYear))

    const res = await getAuditsByYear({ year: auditYear, page, search, ...AdvancedFilters.values, ...extraArgs })
    setColumnSort(res?.data?.sort_by.length > 0 ? res?.data?.sort_by[0] : null)
    dispatch(auditsActions.populateAudits(res?.data?.records, auditYear))

    setPaginatorData({
      currentPage: res?.data?.cur_page ?? 1,
      totalPages: res?.data?.num_pages ?? 1
    })
  }

  useEffect(() => {
    if (contentKey !== content.GANTT) {
      return
    }

    const setInitialValues = async () => {
      try {
        const { data } = await getAuditCommittee(auditYear)
        const parsedData = await parseCommittees(data)
        dispatch({ type: "SET_COMMITTEES", data: parsedData })
      } catch (error) {
        console.error("error", error)
      }
    }
    setInitialValues()
  }, [auditYear, contentKey])

  const getPostponedData = async () => {
    setPostponedAudits(null)
    const params = { ano_auditoria: auditYear }

    if (filterEmpresa) {
      params.empresa = filterEmpresa
    }

    if (filterUN) {
      params.unidad_negocio = filterUN
    }

    const { data } = await getPostponedAudits(params)

    setPostponedAudits(data)
  }

  useEffect(() => {
    const optionsUpdated = [...yearOptions]

    const selectedOption = optionsUpdated.find((el) => +el.label === +auditYear)
    if (selectedOption) {
      selectedOption.selected = true
    }
    setYearOptions(optionsUpdated)

    if (contentKey === content.GANTT) {
      return
    }

    if (contentKey === content.KANBAN) {
      getPostponedData()
    }

    getData()
      .catch(() => {})
      .finally(() => {})
  }, [
    contentKey,
    auditYear,
    startFilter,
    columnSort,
    endFilter,
    filterEmpresa,
    filterUN,
    searchText,
    AdvancedFilters.values
  ])

  useEffect(() => {
    if (contentKey !== content.KANBAN) {
      return
    }

    if (postponedAudits !== null) {
      return
    }

    getPostponedData()
  }, [contentKey])

  const formatPostponedAudits = postponedAudits?.map(({ id, auditoria }) => {
    return {
      ...auditoria,
      status: "APLAZADO",
      aplazamiento_id: id
    }
  })

  const onSelectHandler = ({ label, id }) => {
    localStorage.setItem("selectedYear", label)

    router.replace({
      pathname: "/",
      query: {
        año: label
      }
    })
  }

  const onCreateHandler = () => {
    setAuditOpened(true)
  }

  const toggleManageAuditingCommittee = () => {
    setOpenManageAuditingCommittee(!openManageAuditingCommittee)
  }

  const handleChangeToPrg = (auditId, evaluacionId, divisionId) => {
    dispatch({ type: "PAGE_LOADING", value: t("main_ui.general.lb_saving_changes") })
    updateFrequencyAudit(auditId, "PRG", { evaluacion_id: evaluacionId, subdivision_id: divisionId }).then((result) => {
      dispatchOpenActionModal({ type: "CLOSE" })
      dispatch({ type: "PAGE_LOADING", value: false })
      if (result.data) {
        dispatch(auditsActions.editAudit(auditId, result.data))
        toast.success(t("main_ui.general.lb_saved_changes"))
      }
    })
  }

  const onActionClickHandler = async (audit, action) => {
    switch (action) {
      case rowActions.ENTER:
        if (audit.status === auditStatus.DISCONTINUED.value)
          return toast.error(t("frontend.auditoria.error_nulled_state"))
        router.push(`/auditorias/?auditoria-id=${audit.id}`, undefined, { shallow: false })
        break
      case rowActions.EDIT:
        dispatchOpenActionModal({ type: "OPEN", modal: "EDIT", auditData: audit })
        break
      case rowActions.CLOSE:
        openCloseModal({
          request: async () =>
            await updateAudit({
              auditId: audit.id,
              auditBody: {
                status: auditStatus.CONCLUDED.value
              }
            }),
          onSuccess: (result) => {
            const audit = result.data ?? null
            if (audit === null) {
              return
            }
            dispatch(auditsActions.editAudit(audit.id, audit))
          }
        })
        break

      case rowActions.CHANGE_FREQ:
        if (audit.tipo_frecuencia == "PRG") {
          changeToFreqEspConfirmation({
            request: async () => await updateFrequencyAudit(audit.id, "ESP"),
            onSuccess: (result) => {
              dispatch({ type: "PAGE_LOADING", value: false })
              if (result.data) {
                dispatch(auditsActions.editAudit(audit.id, result.data))
                toast.success(t("main_ui.general.lb_saved_changes"))
              }
            }
          })
        } else {
          dispatchOpenActionModal({ type: "OPEN", modal: "CHANGE_FREQ", auditData: audit })
        }
        break
      case "BECOME_TEMPLATE":
        dispatchOpenActionModal({ type: "OPEN", modal: "BECOME_TEMPLATE", auditData: audit })
        break
      case rowActions.CANCEL:
        let confirmationModal = openCancelModal

        const auditBody = {
          status: auditStatus.DISCONTINUED.value
        }

        if (audit.status === auditStatus.DISCONTINUED.value) {
          auditBody.status = auditStatus.NO_STARTED.value

          confirmationModal = reopenConfirmation
        }

        if (audit.status === auditStatus.CONCLUDED.value) {
          auditBody.status = auditStatus.NO_STARTED.value

          confirmationModal = reopenConfirmation
        }

        confirmationModal({
          request: async () => await updateAudit({ auditId: audit.id, auditBody }),
          onSuccess: (result) => {
            const audit = result.data ?? null
            if (audit === null) {
              return
            }
            dispatch(auditsActions.editAudit(audit.id, audit))
          }
        })

        break

      case rowActions.DELETE:
        openDeleteModal({
          request: async () => await deleteAudit(audit.id),
          onSuccess: () => {
            dispatch(auditsActions.deleteAudit(audit.id))
          }
        })

        break

      case rowActions.POSTPONE:
        dispatchOpenActionModal({ type: "OPEN", modal: "POSTPONE", auditData: audit })
        break

      case rowActions.POSTPONE_REVERT:
        dispatch({ type: "PAGE_LOADING", value: t("main_ui.general.lb_saving") })
        revertPostponedAudit(audit.aplazamiento_id).then(() => {
          dispatch({ type: "PAGE_LOADING", value: false })
          toast.success(t("main_ui.general.lb_saved_changes"))
          getData()
          getPostponedData()
        })
        break
    }
  }

  const renderContent = () => {
    let component = null
    switch (contentKey) {
      case content.KANBAN:
        const formatObjectData = (audit) => ({
          endDate: audit.fecha_fin || null,
          id: audit.codigo,
          name: audit.nombre,
          progress: audit.progreso,
          typeAudit: audit.auditoria_tipo?.nombre,
          leader: audit.lider && audit.lider !== null ? `${audit.lider?.name} (${audit.lider?.sigla})` : null,
          auditType: audit.proceso_tipo?.nombre,
          header: audit.status == "APLAZADO" && (
            <div style={{ position: "relative" }}>
              <span className='ui floating tiny label purple' style={{ float: "right" }}>
                {t("frontend.auditoria.lb_postponed_year")}: {audit.ano_auditoria}
              </span>
            </div>
          ),
          footer:
            audit.status == "APLAZADO" ? (
              <div style={{ textAlign: "center" }}>
                <PostponedRevertAction
                  audit={audit}
                  onConfirm={() => onActionClickHandler(audit, rowActions.POSTPONE_REVERT)}
                />
              </div>
            ) : null
        })

        const cardContent = (row, columnConfig) => {
          return (
            <KanbanCard
              key={row.id}
              onArrowClick={() => onActionClickHandler(row, rowActions.ENTER)}
              color={columnConfig.color}
              data={formatObjectData(row)}
            />
          )
        }

        return (
          <KanbanSection
            data={audits}
            postponedData={formatPostponedAudits}
            auditYear={auditYear}
            cardContent={cardContent}
          />
        )
      case content.LIST:
        component = (
          <AuditListView
            initData={audits}
            paginatorConfig={{
              ...paginatorData,
              columnSort: columnSort || "fecha_inicio",
              onBack: () => {
                getData(paginatorData.currentPage - 1)
              },
              onNext: () => {
                getData(paginatorData.currentPage + 1)
              },
              onChangeSort: (v) => {
                setColumnSort(v)
              }
            }}
            onAction={onActionClickHandler}
          />
        )
        break
      case content.GANTT:
        return (
          <AuditoriaGanttView
            filters={{
              auditYear,
              filterEmpresa,
              filterUN,
              advancedFilters: AdvancedFilters.values
            }}
            startFilter={dateParser(startFilter)}
            endFilter={dateParser(endFilter)}
          />
        )
    }

    return component
  }

  const onButtonToggleChangeHandler = (val) => {
    setContentKey(val)
    router.push({ query: { ...router.query, view: val } })
  }

  const onSearch = useCallback(
    debounce((text) => {
      setSearchText(text)
    }, 500),
    []
  )

  const buildExportParams = () => {
    const urlParams = {
      module: "auditoria/list",
      ano_auditoria: auditYear,
      ...AdvancedFilters.values
    }

    if (filterEmpresa) {
      urlParams.empresa = filterEmpresa
    }

    if (filterUN && filterUN !== "null") {
      urlParams.unidad_negocio = filterUN
    }

    if (searchText) {
      urlParams.search = searchText
    }

    return urlParams
  }

  useEffect(() => {
    if (contentKey != "LIST") {
      manageSidenavItems.clear()
      return
    }

    if (!auditYear) {
      manageSidenavItems.clear()
      return
    }

    manageSidenavItems.set([
      {
        id: "seguimiento",
        tooltip: t("page.auditoria__seguimiento"),
        permission: "pyr_auditoria.seguimiento.access",
        icon: (
          <i className='ui icons '>
            <i className='icon big square outline' />
            <i className='icon tasks' />
          </i>
        ),
        href: "auditorias/seguimiento",
        target: "_self"
      },
      {
        id: "export",
        type: "export_excel",
        permission: "pyr_auditoria.export",
        href: getUrlExportLink(buildExportParams())
      },
      {
        id: "reports",
        tooltip: "Exportar Reportes Consolidados",
        icon: "reports/report",
        componentModal: {
          component: AuditoriaReporteExportModal
        }
      },
      {
        tooltip: "main_ui.sidenav.trashbin",
        icon: "sidenav/trash-undo",
        href: "/auditorias/papelera/",
        target: "_self",
        permission: "trashbin.access"
      }
    ])
  }, [contentKey, auditYear])

  useEffect(() => {
    manageSidenavItems.updateItem("export", { href: getUrlExportLink(buildExportParams()) })
  }, [AdvancedFilters.values, filterEmpresa, filterUN, searchText])

  const isOpenActionModal = (modal) => openActionModal && openActionModal.modal === modal
  const handleCloseActionModal = () => dispatchOpenActionModal({ type: "CLOSE" })

  return (
    <>
      <div className={styles.header}>
        <h3 className={"title"}>
          {t("page.auditoria")} - {t("frontend.auditoria.fields.year")}
        </h3>
        <FormField inputWrapperStyle={{ width: "fit-content", padding: "4px 0" }}>
          <Select
            selectionChange={onSelectHandler}
            initialOptions={yearOptions}
            selectTriggerStyle={{ padding: "5px 8px", fontSize: "86%" }}
          />
        </FormField>
      </div>
      <div>
        <ExpirationAlert />
        <CapacCertificateAlert />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            {hasPermission("pyr_auditoria.add_special") && (
              <div
                style={{
                  paddingRight: 10
                }}
              >
                <Button
                  text='frontend.auditoria.creation.new_special'
                  name='green'
                  type='big'
                  option='normal'
                  handleClick={onCreateHandler}
                />
              </div>
            )}

            {contentKey === content.GANTT ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center"
                }}
              >
                {hasPermission("pyr_auditoria.committee.access") && (
                  <Button
                    text='frontend.auditoria.committee.btn_add'
                    name='normal'
                    type='big'
                    option='normal'
                    loading={auditingCommittees == null ? "frontend.auditoria.committee.lb_loading" : false}
                    handleClick={toggleManageAuditingCommittee}
                  />
                )}
              </div>
            ) : null}
          </div>
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            {contentKey === content.GANTT && (
              <GanttDateFilters
                min={initialStartFilter}
                max={initialEndFilter}
                defaultStart={startFilter}
                defaultEnd={endFilter}
                onChange={({ start, end }) => {
                  setStartFilter(start)
                  setEndFilter(end)
                }}
                onErrorDisplay={(flag = false) => {
                  setGanttDateError(flag)
                }}
              />
            )}

            <EmpresaFilters
              empresaValue={filterEmpresa}
              onChangeEmpresa={(id) => {
                setFilterEmpresa(id)
                localStorage.setItem("ABI.auditoria.selectedCompany", id)
              }}
              unValue={filterUN}
              onChangeUN={(id) => {
                setFilterUN(id)
                localStorage.setItem("ABI.auditoria.selectedBu", id)
              }}
              autoSelectFirst={false}
            />

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>&nbsp;</div>
              {AdvancedFilters.Button({ className: "mini" })}
            </div>

            <InputSearch
              onValueChange={onSearch}
              initialValue={searchText}
              textTooltip='Nombre de la auditoría'
              style={{ width: 192 }}
            />
            <ButtonToggleGroup initialValue={contentKey} onButtonToggleChange={onButtonToggleChangeHandler}>
              <ButtonToggle value={content.KANBAN} tooltip={t("main_ui.general.layout_view_kanban")}>
                <Icon name='kanban' />
              </ButtonToggle>
              <ButtonToggle value={content.LIST} tooltip={t("main_ui.general.layout_view_table")}>
                <Icon name='view_list' />
              </ButtonToggle>
              <ButtonToggle value={content.GANTT} tooltip={t("main_ui.general.layout_view_gantt")}>
                <Icon name='view_gantt' />
              </ButtonToggle>
            </ButtonToggleGroup>
          </div>
        </div>
      </div>
      {AdvancedFilters.Panel()}

      {searchText || filterEmpresa || filterUN || !AdvancedFilters.isEmpty() ? (
        <div style={{ width: "100%" }}>
          <FilterWarning
            onRemoveFilter={() => {
              setSearchText("")
              localStorage.removeItem("ABI.auditoria.selectedCompany")
              localStorage.removeItem("ABI.auditoria.selectedBu")
              setFilterEmpresa(null)
              setFilterUN(null)
              AdvancedFilters.clear()
            }}
          />
        </div>
      ) : null}
      {renderContent()}
      <CreateAudit
        title={t("frontend.auditoria.creation.new_special")}
        auditYear={auditYear}
        open={auditOpened}
        onClose={(type) => {
          switch (type) {
            case closeType.CLOSE:
              setAuditOpened(false)
              break
            case closeType.CREATED:
              setAuditOpened(false)
              //getData();
              break
          }
        }}
      />

      {contentKey === content.GANTT && hasPermission("pyr_auditoria.committee.access") && (
        <ManageAuditingCommittee
          auditYear={auditYear}
          onFinish={() => {
            setOpenManageAuditingCommittee(false)
          }}
          open={openManageAuditingCommittee}
        />
      )}

      {isOpenActionModal("EDIT") && (
        <EditAudit
          isOpen
          auditData={openActionModal.auditData}
          onClose={handleCloseActionModal}
          onEdited={handleCloseActionModal}
        />
      )}

      {isOpenActionModal("POSTPONE") && (
        <AuditPostpone
          isOpen
          auditData={openActionModal.auditData}
          onClose={handleCloseActionModal}
          onAccept={handleCloseActionModal}
        />
      )}
      {isOpenActionModal("CHANGE_FREQ") && (
        <AuditChangeToPrgFreq
          auditData={openActionModal.auditData}
          onClose={handleCloseActionModal}
          onSelect={handleChangeToPrg}
        />
      )}
      {isOpenActionModal("BECOME_TEMPLATE") && (
        <AuditBecomeTemplateWizard auditData={openActionModal.auditData} onClose={handleCloseActionModal} />
      )}
    </>
  )
}
