import React from 'react'
import styles from './style.module.scss'

export default function AdvancedWizardHeader({
  children,
  isActive,
  isLast,
  isAlone,
  style = {}
}) {
  return (
    <div
      style={{ flex: isAlone ? 'none' : 1, ...style }}
      className={`${styles.wizardHeader} ${
        isActive ? styles.wizardHeaderActive : ''
      } ${isLast ? styles.wizardHeaderLast : ''}`}
    >
      {children}
      <div className={styles.headerCurve} />
    </div>
  )
}
